import React, { Suspense, lazy } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";



import './scss/style.scss';


import Spinner from './components/Spinner';
import PrivateRoute from './PrivateRoute';
import { ROUTING_NAMES } from './routing-names';

const Welcome = lazy(() => import('./pages/Welcome'));
const Courses = lazy(() => import('./pages/Courses'));
const Course = lazy(() => import('./pages/Course'));
const Lesson = lazy(() => import('./pages/Lesson'));
const NotFound = lazy(() => import('./pages/NotFound'));
const ConfettiButton = lazy(() => import('./pages/ConfettiButton'));

function App() {

  return (
    <Router>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/" component={Welcome}></Route>
          <Route path={`/${ROUTING_NAMES.COURSE}/:courseId/${ROUTING_NAMES.LESSON}/:lessonId`} component={Lesson}></Route>
          <Route path={`/${ROUTING_NAMES.COURSE}/:courseId`} component={Course}></Route>
          <PrivateRoute path={`/${ROUTING_NAMES.COURSES}`}><Courses /></PrivateRoute>
          <Route path="/button" component={ConfettiButton}></Route>
          <Route path="" component={NotFound}></Route>
        </Switch>
      </Suspense>
      {process.env.NODE_ENV === 'development' && <div className="development-notice">Development {process.env.REACT_APP_VERSION}</div>}
    </Router>
  );
}

export default App;
