import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector } from "react-redux";
import Spinner from './components/Spinner';

const PrivateRoute = ({ children, ...remainingProps }) => {
    const auth = useSelector(state => state.firebase.auth);
    console.log('auth', auth);
    return (
        <Route
            {...remainingProps}
            render={({ location }) =>
                !isLoaded(auth) ? <Spinner /> :
                    isLoaded(auth) && !isEmpty(auth) ? (
                        children
                    ) : (
                            <Redirect
                                to={{
                                    pathname: "/",
                                    state: { from: location },
                                }}
                            />
                        )
            }
        />
    );
};
export default PrivateRoute;